import React from 'react';
import './Category.scss';
import images from './images.js';

const Category = (props) => {
    ////   add description on home page about what they do.
    ///    adding a location for where they serve customers.
    ///    

    let category = '';
    let categoryImages = [];
    //
    if(window.location.pathname === '/category/concrete'){
        category = 'Concrete';
        categoryImages = images.concrete.map((el,i)=>{
            return (
                <a key={i} href={el}>
                    <img className='concreteImg' src={el} alt=""/>
                </a>
                
            )
        })
    } 
    //
    else if(window.location.pathname === '/category/tileAndGrout'){
        category = 'Tile and Grout';
        categoryImages = images.tile.map((el,i)=>{
            return (
                <a key={i} href={el}>
                    <img className='concreteImg' src={el} alt=""/>
                </a>
                
            )
        })
    } 
    //
    else if (window.location.pathname === '/category/rubberVinylEtc'){
        category = 'VCT, LVT and Rubber Flooring';
        categoryImages = images.vinyl.map((el,i)=>{
            return (
                <a key={i} href={el}>
                    <img key={i} src={el} alt=""/>
                </a>
                
            )
        })
    } 
    else if (window.location.pathname === '/category/counterTops'){
        category = 'Countertops';
        categoryImages = images.counters.map((el,i)=>{
            return (
                <a key={i} href={el}>
                    <img key={i} src={el} alt=""/>
                </a>
                
            )
        })
    }
    else console.log('ouch', window.location.pathname)
   
    return (
        <div className="categoryP">
            <div className="categoryC">
                <div>
                    <h1>{category}</h1>
                </div>
                <div>
                    {categoryImages}
                    <button onClick={()=> props.history.push('/')}>Back</button>
                </div>

            </div>
        </div>
    );
};

export default Category;