import React, { Component } from 'react'
import './NavBar.scss';
import title from '../../images/TVS-Header-AXD.png';
import {Link} from 'react-router-dom';


export default class NavBar extends Component {
    
  render() {
    return (
      <div className='navbarp'>
          <div className='navbarc'>
                <img src={title} alt=""/>
                {/* <h1>TVS</h1> */}
          </div>
          <div className='navbarlinks'>
              <div onClick={this.props.toggleTabs} className='tabdropdown'>❖</div>
              <div className="desktoplinks">
                <Link className='navlinks' onClick={()=>this.props.toggleTabs(false)} to='/' >Home</Link>
                <Link className='navlinks' onClick={()=>this.props.toggleTabs(false)} to='/about'>About</Link>
                <Link className='navlinks' onClick={()=>this.props.toggleTabs(false)} to='/jobs'>Jobs</Link>
                <Link className='navlinks' onClick={()=>this.props.toggleTabs(false)} to='/contact'>Contact</Link>
              </div>
          </div>
      </div>
    )
  }
}
