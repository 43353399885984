import React, { Component } from 'react';
import './Jobs.scss';
import image1 from './images/beforeAfter1.png';
import image2 from './images/beforeAfter2.png';
import image3 from './images/beforeAfter3.png';
import image4 from './images/beforeAfter4.png';
import image5 from './images/beforeAfter5.png';
import image6 from './images/beforeAfter6.png';
import image7 from './images/beforeAfter7.png';

export default class Jobs extends Component {
    constructor(){
        super();
        this.state = {
            toggleInfo: false,
            toggleInfo2: false,
            toggleInfo3: false,
            toggleInfo4: false,
            toggleInfo5: false,
            toggleInfo6: false,
            toggleInfo7: false,
        }
    }

    showInfo = (key, classN )=>{
        // console.log(classN);
        this.setState({
            [key]: !this.state[key]
        })
        // if(classN){
        //     let chng = document.getElementById(classN)
        //     chng.scrollIntoView({behavior: "smooth", block: "end"});
        // }
       
    }

    render() {
        return (
            <div className='jobsp'>
                <div className="jobsc">

                    <div style={{margin: '1vh 0'}}><img className='jobsimg' src={image1} alt=""/></div>
                    {/* <div className="imgoverlay">Hello</div> */}
                   
                    {/* {this.state.toggleInfo ? 
                    <div className='picInfo'>
                    <div className='showDesc' onClick={e=>this.showInfo('toggleInfo')}>▿</div>
                        <p>Picture Description</p>
                    </div>
                    :
                    <>
                    <div className='descriptionDiv'>Show Description</div>
                    <div className='showDesc' onClick={e=>this.showInfo('toggleInfo')}>▿</div>
                    </>
                    } */}

                    <div style={{margin: '1vh 0'}}><img id='2' className='jobsimg' src={image2} alt=""/></div>
                    
                    {/* {this.state.toggleInfo2 ? 
                    <div className='picInfo'>
                        <div className='showDesc' onClick={e=>this.showInfo('toggleInfo2')}>▿</div>
                        <p>Picture Description</p>
                    </div>
                    :
                    <>
                    <div className='descriptionDiv'>Show Description</div>
                    <div className='showDesc' onClick={e=>this.showInfo('toggleInfo2')}>▿</div>
                    </>
                    } */}

                    <div style={{margin: '1vh 0'}}><img className='jobsimg' src={image3} alt=""/></div>
                    
                    {/* {this.state.toggleInfo3 ? 
                    <div className='picInfo'>
                        <div className='showDesc' onClick={e=>this.showInfo('toggleInfo3')}>▿</div>
                        <p>Picture Description</p>
                    </div>
                    :
                    <>
                    <div className='descriptionDiv'>Show Description</div>
                    <div className='showDesc' onClick={e=>this.showInfo('toggleInfo3')}>▿</div>
                    </>
                    } */}

                    <div style={{margin: '1vh 0'}}><img className='jobsimg' src={image4} alt=""/></div>
                    
                    {/* {this.state.toggleInfo4 ? 
                    <div className='picInfo'>
                        <div className='showDesc' onClick={e=>this.showInfo('toggleInfo4')}>▿</div>
                        <p>Picture Description</p>
                    </div>
                    :
                    <>
                    <div className='descriptionDiv'>Show Description</div>
                    <div className='showDesc' onClick={e=>this.showInfo('toggleInfo4')}>▿</div>
                    </>
                    } */}

                    <div style={{margin: '1vh 0'}}><img className='jobsimg' src={image5} alt=""/></div>
                    
                    {/* {this.state.toggleInfo5 ? 
                    <div className='picInfo'>
                        <div className='showDesc' onClick={e=>this.showInfo('toggleInfo5')}>▿</div>
                        <p>Picture Description</p>
                    </div>
                    :
                    <>
                    <div className='descriptionDiv'>Show Description</div>
                    <div className='showDesc' onClick={e=>this.showInfo('toggleInfo5')}>▿</div>
                    </>
                    } */}

                    <div style={{margin: '1vh 0'}}><img className='jobsimg' src={image6} alt=""/></div>
                    
                    {/* {this.state.toggleInfo6 ? 
                    <div className='picInfo'>
                        <div className='showDesc' onClick={e=>this.showInfo('toggleInfo6')}>▿</div>
                        <p>Picture Description</p>
                    </div>
                    :
                    <>
                    <div className='descriptionDiv'>Show Description</div>
                    <div className='showDesc' onClick={e=>this.showInfo('toggleInfo6')}>▿</div>
                    </>
                    } */}

                    <div style={{margin: '1vh 0'}}><img className='jobsimg' src={image7} alt=""/></div>
                    
                    {/* {this.state.toggleInfo7 ? 
                    <>
                    <div className='picInfo'>
                        <div className='showDesc' onClick={e=>this.showInfo('toggleInfo7')}>▿</div>
                        <p>Picture Description</p>
                    </div>
                    <div style={{height:'10vh',width: '100vw'}}></div>
                    </>
                    :
                    <>
                    <div className='descriptionDiv'>Show Description</div>
                    <div className='showDesc' onClick={e=>this.showInfo('toggleInfo7')}>▿</div>
                    <div style={{height:'10vh',width: '100vw'}}></div>
                    </>
                    } */}
                    <div style={{height:'5vh',width: '100vw'}}></div>
                </div>
            </div>
        );
    }
}