import React, { Component } from "react";
import "./About.scss";
import {Tab} from '../Home/Home.jsx';

export default class About extends Component {
    constructor(){
        super();
        this.state = {

        }
    }
  render() {
    return (
      <div className="aboutp">
        <div className="aboutc">
          <div>
            <h1>We are T.V.S floor coatings and restoration.</h1>
            <h2>Tile Vinyl and Stone</h2>
            <p>
              <Tab />We specialize in repairing and protecting your flooring surfaces.
              <br /><br />
              <Tab />We are an authorized dealer of NeverStrip floor coatings.
              NeverStrip Floor Coatings™-Exceptional Floor Coatings NeverStrip
              Floor Coatings is a manufacturer of exceptional floor coatings.
              Its products are used in commercial, residential and industrial
              facilities. These products lower floor care costs, enhance the
              appearance of floors and often improve traction or the floors
              coefficient of friction. NeverStrip has a North American network
              of Authorized Contractor Dealers in over 75 cities.
              <br /><br />
              <Tab />Customers say NeverStrip provides exceptional floor coatings and first class
              service and support. 
              <br /><br />
              <Tab />Authorized Dealers and their Certified staff
              offer NeverStrip based floor care solutions to residential, health
              care, retail, restaurants, education, airports, convention
              centers, corporate office and property management customers.
              <br /><br />
              <Tab />Dealers successfully implement regional and national rollouts of
              NeverStrip’s exceptional floor coating solutions for customers
              across the United States and Canada.
              <br /><br />
              <Tab />NeverStrip exceptional floor coatings provide substantial business benefits including lower
              ongoing maintenance costs, improved appearance and better floor
              protection. 
              <br /><br />
              <Tab />Solutions are available for resilient and non-resilient hard floor surfaces. These surfaces include: Cork,
              VCT, LVT, VAT (vinyl asbestos tile), Ceramic, Porcelain, Terrazzo,
              Concrete and Natural Stones. NeverStrip products are applied to
              school hallways, cafeterias, gymnasiums, locker rooms and
              classrooms; restrooms, lobbies and warehouse and factory floors.
              <br /><br />
              <Tab />NeverStrip exceptional floor coatings come in five Categories:
              Micron, Thin Film, Tile and Grout, High Build and Instant Film.
            </p>
          </div>
          <div>
            <p>Products we Use</p>
            <a href="http://www.neverstrip.com/">
              <i>NeverStrip</i>
            </a>
            <br />
            <div>
              <p>Links for more info</p>
              <a href="http://www.neverstrip.com/easier-to-clean-floors/">
                Case Studies
              </a>
              <a href="http://www.neverstrip.com/blog/">Blog</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
