import React, { Component } from 'react';
import './Contact.scss';
import axios from 'axios';
import {Tab} from '../Home/Home.jsx';

export default class Contact extends Component {
    constructor(){
        super();
        this.state = {
            name: '',
            email: '',
            message: ''
        }
    }

    sendGrid = (e)=>{
        e.preventDefault();
        // const {name,email,message} = this.state;
        axios.post('/api/sendGrid', {...this.state}).then(res=>{
            if(res.data === 'done'){
                alert('message sent')
                this.props.history.push('/')
            }   else alert('message failed')
            // console.log(res.data)
        })
    }

    handleChange = (key,val)=>{
        this.setState({
            [key]: val
        })
    }

    render() {
        const {name,email,message} = this.state;
        return (
            <div className='contactp'>
                
                {/* <form className='contactc' action={`https://formspree.io/${email}`} method="POST">
                    <input placeholder='Name' type="text" name="name" />
                    <input placeholder='email' type="email" name="_replyto" />
                    <input placeholder='message' type="text" name='message'/>
                    <input type="submit" value="Send" />
                    <input type="hidden" name="_next" value="tvs.devtc.pro"/>
                </form> */}
                <form onSubmit={this.sendGrid} className='contactc'>
                    <h1>
                        Let us know what we <br/> can do for you!
                    </h1>
                    <p className='contactpar'>
                        <Tab />If you have any questions don't hesitate to contact us here. 
                        We will contact you through the email you provide 
                        unless there is another preferred method of contact.
                    </p> 
                    <input onChange={e=>this.handleChange('name', e.target.value)} placeholder='Name..' type="text" required/>
                    <input onChange={e=>this.handleChange('email', e.target.value)} placeholder='Email..' type="email" required/>
                    <input onChange={e=>this.handleChange('message', e.target.value)} placeholder='Message..' type="text" required/>
                    {name && email && message ? 
                        <input className='submitCon' type="submit"/> 
                        : 
                        <button disabled>Complete Form To Submit</button>}
                    {/* <a href="tel:16029193910"><span class="bottom">Call us now</span></a> */}
                </form>

            </div>
        );
    }
}