import React from 'react';
import {Switch, Route, } from 'react-router-dom';
import Home from './components/Home/Home.jsx';
import About from './components/About/About.jsx';
import Contact from './components/Contact/Contact.jsx';
import Jobs from './components/Jobs/Jobs.jsx';
import Category from './components/Category/Category.jsx';



export default (
    <Switch>
        <Route exact path='/' component={Home} />
        <Route path='/about' component={About} />
        <Route path='/jobs' component={Jobs} />
        <Route path='/contact' component={Contact} />
        <Route path='/category/:type' component={Category} />
        <Route component={Home} />
    </Switch>
)