import React, { Component } from 'react';
import './App.scss';
import routes from './routes.js';
import NavBar from './components/NavBar/NavBar.jsx';
import MobileTabs from './components/MobileTabs/MobileTabs.jsx';
import Footer from './components/Footer/Footer.jsx';

class App extends Component {
  constructor() {
      super();
      this.state = {
        showTabs: false
      }
  }

  toggleTabs = (bool)=>{
    if(bool === false){ 
      return this.setState({
      showTabs: bool
    })} else {
      return this.setState({
      showTabs: !this.state.showTabs
    })}
  }

  render() {
    const {showTabs} = this.state;
    return (
      <div className="App">
        {showTabs && <MobileTabs toggleTabs={this.toggleTabs} />}
          <NavBar toggleTabs={this.toggleTabs} />
          <div className='routes'>{routes}</div>
          <Footer />
      </div>
    );
  }
}

export default App;
