import React, { Component } from 'react';
import './MobileTabs.scss';
import {Link} from 'react-router-dom';

export default class MobileTabs extends Component {
    render() {
        return (
            <div className='mtabsp'>
                <Link onClick={()=>this.props.toggleTabs(false)} to='/' >Home</Link>
                <Link onClick={()=>this.props.toggleTabs(false)} to='/about'>About</Link>
                <Link onClick={()=>this.props.toggleTabs(false)} to='/jobs'>Jobs</Link>
                <Link onClick={()=>this.props.toggleTabs(false)} to='/contact'>Contact</Link>
            </div>
        );
    }
}