import React, { Component } from "react";
import "./Home.scss";
import CategoryIMG from "../CategoryIMG/CategoryIMG";
import OpeningInfo from "../OpeningInfo/OpeningInfo";
import imageC from "./CategoryImgs/CategoryC.png";
import imageV from "./CategoryImgs/CategoryV.png";
import imageT from "./CategoryImgs/CategoryTnG.png";
// import imageCounter from "./CategoryImgs/CategoryCount.png";

export default class Home extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div className="homep">
        <div className="homec">
          <CategoryIMG
            to={"/category/tileAndGrout"}
            title={"Tile and Grout floors"}
            img={imageT}>
            <Tab />Tile and grout can be a lot of work to maintain. By using NeverStrip
            tile seal we can protect the surface of the tile and grout. Easy to
            maintain and to keep clean.
          </CategoryIMG>
          <CategoryIMG
            to={"/category/concrete"}
            title={"Concrete floors"}
            img={imageC}>
            <Tab />We offer concrete grinding and polishing with various topcoat
            options. Including stained concrete urethane topcoats polyaspartic
            epoxy and metallic coatings.
          </CategoryIMG>
          <CategoryIMG
            to={"/category/rubberVinylEtc"}
            // Rubber, Vinyl, and related floors
            title={"VCT, LVT, and Rubber Flooring"}
            img={imageV}>
            {/* <Tab />Thin Film-Exceptional Floor Coatings for Resilient Flooring The
            NeverStrip Thin Film Category has exceptional urethane floor
            coatings. These products include: 
            
            <br /><br />∙  NeverStrip Gloss™
             <br />∙  NeverStrip Satin™
             <br />∙  NeverStrip Matte™
             <br />∙  NeverStrip Color™
             <br />∙  NeverStrip High Solids Urethane™
             <br />∙  NeverStrip Extreme Performance™
            
             <br /><br /><Tab /> NeverStrip Gloss, Satin, Matte and Color are exceptional floor coatings for resilient flooring within schools. These products are two-part, water-based urethane coatings applied at 350-400 sq. feet per gallon. Core benefits include: 
            
             <br /><br />✓   An outstanding appearance
             <br />✓   Lower ongoing maintenance costs
             <br />✓   Many years of use (often times 5 years or
                        more) between re coats.
            
            <br /><br /><Tab /> These products replace conventional floor
            finish or wax. Targeted applications are hallways, class rooms,
            gymnasiums, cafeterias and multi-purpose rooms. Vinyl Composition
            Tile (VCT), Vinyl Asbestos Tile (VAT), Luxury Vinyl Tile (LVT),
            Sheet Vinyl and Linoleum are appropriate flooring.
            <button><a id='homeATag' href='http://www.neverstrip.com/cms/neverstrip-gloss-on-vct-and-sheet-vinyl/' target='_'>Click Here</a></button>
            to read Blogs concerning the use of these products in schools.
            <br /><br />
            <Tab />NeverStrip High Solids Urethane has best in class abrasion, stain
            and chemical resistance. These exceptional floor coatings are well
            suited for demanding commercial, warehouse and industrial
            environments. High Solids Urethane is almost always used as a
            topcoat with NeverStrip epoxy primers and body coats. High Solids
            Urethane can be in clear or color. The product can be further
            enhanced with NeverStrip Grip aggregate for even more abrasion
            resistance and improved coefficient of friction.
            <button><a href='http://www.neverstrip.com/cms/wp-content/uploads/2015/10/NS_PDS_HighSolids.pdf' target='_'>Click Here</a></button>
            for the High Solids Urethane Product Data Sheet to learn more. */}
            <Tab /> NeverStrip Vinyl Seal™ for resilient flooring including VCT, LVT, linoleum and rubber.  NeverStrip Micron Primer™ is used below Vinyl Seal on VCT or vinyl composition tile.  Vinyl Seal and Micron Primer can be burnished.  Typically, this System requires 50+% less scrub and redcoats and burnishing than wax.  Stripping is eliminated!  
          </CategoryIMG>
          {/* <CategoryIMG 
                        to={'/category/counterTops'} 
                        title={'Counter tops'} 
                        img={imageCounter}>
                            description
                    </CategoryIMG> */}
            <OpeningInfo 
              title='Quick Contact'
              // desc='Get ahold of us if...'
              name={['Jim', 'Shelby']}
              number={['12084971697', '12083907234']}
            />
        </div>
      </div>
    );
  }
}


export function Tab(){
    return (
       <span className='indent'></span>
    )
}