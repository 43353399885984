////// vinyl
const imagev10 = require('./images/vba1.png')
// const imagev11 = require('./images/vba2.png')
// const imagev12 = require('./images/vba3.1.png')
// const imagev13 = require('./images/vba4.png')
const imagev14 = require('./images/vba5.png')
// const imagev15 = require('./images/vba6.png')
const imagev16 = require('./images/vba7.png')

////// tile
const imaget1 = require('./tileImages/tba1.png')
const imaget2 = require('./tileImages/tba2.png')
const imaget4 = require('./tileImages/tba4.png')
const imaget5 = require('./tileImages/tba5.png')
const imaget6 = require('./tileImages/tba6.png')
const imaget7 = require('./tileImages/tba7.png')
const imaget8 = require('./tileImages/tba8.png')

////// concrete
// const imagec1 = require('./concreteImages/cba1.png')
const imagec2 = require('./concreteImages/cba2.png')
// const imagec3 = require('./concreteImages/cba3.png')
const imagec3 = require('./concreteImages/cba3.1.png')
// const imagec4 = require('./concreteImages/cba4.png')
// const imagec5 = require('./concreteImages/cba5.png')
const imagec6 = require('./concreteImages/concrete.jpg')
const imagec7 = require('./concreteImages/concrete1.jpg')
const imagec8 = require('./concreteImages/concrete2.jpg')

///// counters
const imagecounter1 = require('./counters/counterba1.png')



module.exports = {
    concrete: [
        // imagec1,
        imagec2,imagec3,
        // imagec4,imagec5,
        imagec6,imagec7,
        imagec8
    ],
    tile: [
        imaget1,imaget2,
        imaget4,imaget5,imaget6,
        imaget7,imaget8
    ],
    vinyl: [
        imagev16,imagev14,
        imagev10
    ],
    counters: [
        imagecounter1
    ]
}