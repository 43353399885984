import React from 'react';
import './CategoryIMG.scss';
import {Link} from 'react-router-dom';

const CategoryIMG = (props) => {
    return (
        <>
            <Link className='categoryLinks' to={props.to}>
                {/* <img src={props.img} alt=""/> */}
                <img className='catImg' src={props.img} alt=""/>
                <h1>{props.title}</h1>
            </Link>
            <div className='categoryLinks'>
                <p>{props.children}</p>
            </div>
        </>
    );
};

export default CategoryIMG;