import React, { Component } from 'react';
import './OpeningInfo.scss';

export default class OpeningInfo extends Component {

    render() {
        return (
            <div className='openingInfoP'>
                <h1><i>{this.props.title}</i></h1>
                <h2>{this.props.desc}</h2>
                <span>
                    <a href={`tel:${this.props.number[0]}`}>
                        Click here to call {this.props.name[0]}
                    </a>
                    <em>Call {this.props.name[0]} at (208)497-1697</em>
                </span>
                <hr/>
                <span>
                    <a href={`tel:${this.props.number[1]}`}>
                        Click here to call {this.props.name[1]}
                    </a>
                    <em>Call {this.props.name[1]} at (208)390-7234</em>
                </span>
                <hr/>
            </div>
        );
    }
}